import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
require('./main.css')

/* 3rd Party Packages */
import { Vue3Mq } from "vue3-mq";

const app = createApp(App)

app.use(router)
app.use(Vue3Mq, {
    breakpoints: {
        brMobile: 0,
        brTablet: 740,
        brLaptop: 1000,
        brDesktop: 1660,
    }
})


app.mount('#app')