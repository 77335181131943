<template>
  <div id="app">
    <transition name="fade">
      <NavMobile v-if="isMobile" />
      <Nav v-else />
    </transition>
    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <div :key="route.name">
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import NavMobile from "@/components/core/NavMobile.vue";
import mixins from "@/javascript/mixins.js";
import Nav from "@/components/core/Nav.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { NavMobile, Nav },
};
</script>

<style>
#app {
  overflow-x: hidden;
}
</style>
