<template>
  <section
    id="hometeam"
    class="reveal reveal-fade"
    aria-labelledby="hometeam-title"
    :class="mq"
  >
    <div class="section img-overlay true-center">
      <h1 id="hometeam-title">
        Top-notch dentistry, from Romsey&#39;s top dentists.
      </h1>
      <Button value="Meet The Team" @click="navTeam" />
    </div>
    <img
      :src="image"
      alt="Patient lying down in chair while dentist shows
    dental x-ray."
    />
  </section>
</template>

<script>
import Button from "@/components/global/Button.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { Button },
  methods: {
    navTeam() {
      this.$router.push("/about?our-team");
    },
  },
  computed: {
    image() {
      let type = "dentist-and-patient.webp";

      if (this.isMobile) type = "dentist-and-patient-mob.webp";

      return require("@/assets/images/" + type);
    },
  },
};
</script>

<style scoped>
#hometeam {
  position: relative;
  overflow: hidden;
  color: white;
  height: 700px;
  width: 100%;
}

#hometeam > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#hometeam > .true-center {
  flex-direction: column;
  gap: 60px;
}

#hometeam-title {
  transition: var(--animation);
  font-size: var(--font-title);
  font-weight: normal;
  text-align: center;
  width: 600px;
}

.brMobile #hometeam-title {
  font-size: var(--font-big);
  width: unset;
}
</style>
