<template>
  <div class="disclaimer click true-center" @click="nav">
    <ExclamationIcon />
    <p>Disclaimer</p>
    <p class="disclaimer-txt">| This is a demo wesbite only</p>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/outline";

export default {
  components: { ExclamationIcon },
  methods: {
    nav() {
      this.$router.push("/about");
    },
  },
};
</script>

<style scoped>
.disclaimer {
  border-radius: var(--border-radius);
  background: rgba(7, 17, 57, 0.4);
  height: var(--size-textfield);
  backdrop-filter: blur(6px);
  align-items: center;
  padding-right: 5px;
  padding-left: 15px;
  position: fixed;
  color: white;
  z-index: 95;
  bottom: 10px;
  left: 10px;
  gap: 10px;
}

.disclaimer:hover {
  background: var(--primary);
  padding: 0 15px 0 15px;
  width: min-content;
  color: white;
  gap: 10px;
}

.disclaimer:active {
  background: var(--accent);
  color: white;
}

.disclaimer > svg {
  height: var(--size-icon);
  width: var(--size-icon);
}

.disclaimer p {
  font-size: var(--font-small);
  margin-top: 4px;
}

.disclaimer-txt {
  white-space: nowrap;
  visibility: hidden;
  max-width: 0;
  opacity: 0;
}

.disclaimer:hover .disclaimer-txt {
  visibility: visible;
  max-width: unset;
  opacity: 1;
}
</style>
