<template>
  <Page id="home">
    <HomeLanding />
    <section
      class="section-indent section-top reveal reveal-fade"
      aria-labelledby="aria-homestatement"
      :class="mq"
    >
      <h1 id="aria-homestatement">
        Gentle, friendly treatment from our locally-owned practice.
      </h1>
      <p>
        Our decades of industry experience are what allow us to deliver quality,
        friendly and professional service to every single patient we treat.
        Check out some of our testimonials from just a handful of our many happy
        patients.
      </p>
    </section>
    <Seperator />
    <HomeTreatments />
    <HomeTeam />
    <HomeTestimonials />
    <HomePartners />
    <Report />
  </Page>
</template>

<script>
import HomeTestimonials from "@/components/pages/home/HomeTestimonials.vue";
import HomeTreatments from "@/components/pages/home/HomeTreatments.vue";
import HomePartners from "@/components/pages/home/HomePartners.vue";
import HomeLanding from "@/components/pages/home/HomeLanding.vue";
import HomeTeam from "@/components/pages/home/HomeTeam.vue";
import Seperator from "@/components/global/Seperator.vue";
import Page from "@/components/core/Page.vue";
import Report from "@/components/Report.vue";

export default {
  name: "Home",
  inject: ["mq"],
  components: {
    HomeTestimonials,
    HomeTreatments,
    HomePartners,
    HomeLanding,
    Seperator,
    HomeTeam,
    Report,
    Page,
  },
};
</script>

<style scoped></style>
