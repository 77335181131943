import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Showcase - Happy Dental',
    component: Home
  },
  { path: '/about', name: 'About', component: () => import('../views/About.vue') },
  { path: '/our-team', name: 'Our Team', component: () => import('../views/OurTeam.vue') },
  { path: '/treatments', name: 'Treatments', component: () => import('../views/Treatments.vue') },
  { path: '/contact', name: 'Contact', component: () => import('../views/Contact.vue') },
  { path: '/privacy', name: 'Privacy Policy', component: () => import('../views/PrivacyPolicy.vue') },

  /* Error 404 */
  { path: '/:pathMatch(.*)*', name: 'Error 404', component: () => import('../views/E404.vue') }

]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  window.scrollTo({
    behavior: "smooth",
    top: 0,
  });


  document.title = to.name
})

export default router
