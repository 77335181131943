<template>
  <section
    id="hometestimonials"
    class="section-indent section-top reveal reveal-fade"
    aria-labelledby="aria-hometestimonials"
    :class="mq"
  >
    <div class="section-top">
      <h1 id="aria-hometestimonials">Testimonials</h1>
      <p>
        Read about all the smiles we&#39;ve put on people&#39;s faces thanks to
        ourquality and friendly service!
      </p>
    </div>
    <div id="hometestimonials-content" class="reveal">
      <Testimonial
        v-for="(r, index) in ratings"
        :class="'delay' + index"
        :rating="r.rating"
        :value="r.value"
        :name="r.name"
        :key="r.name"
        @click="nav"
      />
    </div>
    <Button value="See More" :small="true" @click="nav" />
  </section>
</template>

<script>
import Testimonial from "@/components/Testimonial.vue";
import Button from "@/components/global/Button.vue";

export default {
  inject: ["mq"],
  components: { Testimonial, Button },
  data() {
    return {
      ratings: [
        {
          rating: 5,
          name: "Bob B",
          value:
            "...Professional, courteous, and friendly staff made me feel like family... would highly recommend to anyone!",
        },
        {
          rating: 4,
          name: "Barbara",
          value:
            "...The staff are friendly, respectful, caring and courteous of your time...",
        },
        {
          rating: 5,
          name: "James",
          value:
            "...Is always amazing service very quality of customer service and fantastic results...",
        },
      ],
    };
  },
  methods: {
    nav() {
      this.$router.push("/about?testimonials");
    },
  },
};
</script>

<style scoped>
#hometestimonials,
#hometestimonials-top {
  flex-direction: column;
  text-align: center;
  display: flex;
  gap: 20px;
}

#hometestimonials {
  align-items: center;
  gap: 40px;
}

#hometestimonials-content {
  display: flex;
  width: 100%;
  gap: 40px;
}

.brLaptop #hometestimonials-content {
  gap: 20px;
}

.brTablet #hometestimonials-content,
.brMobile #hometestimonials-content {
  flex-direction: column;
  gap: 20px;
}

#hometestimonials-content > .testimonial {
  opacity: 0;
}

#hometestimonials-content.reveal-active > .testimonial {
  opacity: 1;
}
</style>
