<template>
  <div class="testimonial click-scale">
    <div class="testimonial-top">
      <h3>{{ name }}</h3>
      <div class="testimonial-stars">
        <Solid v-for="s in rating" :key="s" />
        <Outline v-for="o in 5 - rating" :key="o" />
      </div>
    </div>
    <p>{{ value }}</p>
  </div>
</template>

<script>
import { StarIcon as Outline } from "@heroicons/vue/outline";
import { StarIcon as Solid } from "@heroicons/vue/solid";

export default {
  props: {
    rating: Number,
    value: String,
    name: String,
  },
  components: {
    Outline,
    Solid,
  },
};
</script>

<style scoped>
.testimonial {
  border-radius: var(--border-radius);
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  flex-direction: column;
  background: white;
  text-align: start;
  padding: 25px;
  display: flex;
  gap: 20px;
  flex: 1;
}

.testimonial-top {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.testimonial-top > h3 {
  font-weight: 500;
}

.testimonial-stars {
  display: flex;
  gap: 2px;
}

.testimonial-stars > svg {
  height: var(--size-icon);
  width: var(--size-icon);
  color: var(--accent);
}
</style>