<template>
  <div id="navmob" :class="{ 'navmob-open': isOpen }">
    <div id="navmob-top">
      <Logo id="navmob-logo" class="click-scale" @click="navHome" />
      <button
        @click="isOpen = !isOpen"
        id="navmob-menu"
        aria-label="Mobile Navigation Menu Button"
      >
        <svg viewBox="0 0 100 100">
          <path
            class="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path class="line line2" d="M 20,50 H 80" />
          <path
            class="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </button>
    </div>
    <transition name="slide">
      <div v-if="isOpen" id="navmob-content">
        <router-link
          v-for="r in routes"
          :key="r.name"
          :to="r.path"
          class="navmob-option"
          @click="close"
          :class="{ 'navmob-active': isRoute(r.path) }"
        >
          {{ r.name }}
        </router-link>
        <div style="width: 100%; padding: 0 20px 0 20px; margin-top: auto">
          <Button
            value="Request Appointment"
            :full="true"
            @click="navContact"
          />
        </div>
        <div style="min-height: 40px" />
      </div>
    </transition>
  </div>
</template>

<script>
import Button from "@/components/global/Button.vue";
import mixin from "@/javascript/mixins.js";
import Routes from "@/router/routes.json";
import Logo from "@/assets/Logo.vue";

export default {
  mixins: [mixin],
  components: { Button, Logo },
  data() {
    return { isOpen: false, routes: Routes };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    isRoute(r) {
      if (r == this.$route.fullPath) return true;
    },
    navHome() {
      this.close();
      this.$router.push("/");
    },
    navContact() {
      this.close();
      this.$router.push("/about?appointments");
    },
  },
};
</script>

<style scoped>
#navmob {
  height: var(--size-nav-mob);
  max-width: 100%;
}

#navmob-top {
  padding: 0 var(--padding-mobile) 0 var(--padding-mobile);
  box-shadow: var(--box-shadow-light);
  justify-content: space-between;
  height: var(--size-nav-mob);
  align-items: center;
  background: white;
  position: fixed;
  display: flex;
  z-index: 90;
  width: 100%;
}

.navmob-open #navmob-top {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
}

#navmob-logo {
  height: 32px;
}

#navmob-menu {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  border: none;
  height: 45px;
  width: 45px;
  padding: 0;
}

#navmob-menu > svg {
  height: 100%;
  width: 100%;
}

#navmob-menu .line {
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  stroke: var(--primary);
  stroke-width: 2;
  fill: none;
}

#navmob-menu .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

#navmob-menu .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

#navmob-menu .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.navmob-open #navmob-menu .line {
  stroke: var(--accent);
  stroke-width: 6;
}

.navmob-open #navmob-menu .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

.navmob-open #navmob-menu .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}

.navmob-open #navmob-menu .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

#navmob-content {
  animation: var(--enter-right);
  transition: var(--animation-long);
  top: var(--size-nav-mob);
  flex-direction: column;
  padding: 40px 0 40px 0;
  align-items: center;
  background: white;
  overflow: scroll;
  position: fixed;
  display: flex;
  z-index: 100;
  height: 100%;
  width: 100%;
  gap: 20px;
  right: 0;
}

.navmob-option {
  padding: 15px 0 15px 20px;
  text-decoration: unset;
  color: var(--primary);
  align-items: center;
  text-align: start;
  width: 100%;
}

.navmob-option:hover {
  background: var(--accent);
  color: white !important;
}

.navmob-active {
  background: var(--primary);
  color: white;
}
</style>
