<template>
  <div id="nav" class="section" :class="mq">
    <Logo id="nav-logo" class="click-scale" @click="navHome" />
    <div id="nav-options">
      <div
        v-for="(r, i) in routes"
        :key="r.name"
        @mouseleave="index = null"
        @mouseover="index = i"
        class="click nav"
      >
        <router-link
          :to="r.path"
          class="nav-route"
          :class="{ 'nav-active': isRoute(r.path) }"
        >
          {{ r.name }}
        </router-link>
        <transition name="fade">
          <div v-if="index == i || isRoute(r.path)" class="nav-indicator" />
        </transition>
      </div>
      <Button
        value="Appointments"
        @click="navContact"
        :small="isTablet"
        :bg="true"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/global/Button.vue";
import mixins from "@/javascript/mixins.js";
import Routes from "@/router/routes.json";
import Logo from "@/assets/Logo.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    Button,
    Logo,
  },
  data() {
    return {
      routes: Routes,
      index: null,
    };
  },
  methods: {
    navHome() {
      this.$router.push("/");
    },
    navContact() {
      this.$router.push("/about?appointments");
    },
    isRoute(r) {
      if (r == this.$route.fullPath) return true;
    },
  },
};
</script>

<style scoped>
#nav {
  box-shadow: var(--box-shadow-light);
  justify-content: space-between;
  transition: var(--animation);
  height: var(--size-nav);
  background: white;
  align-items: center;
  position: fixed;
  display: flex;
  z-index: 100;
  width: 100%;
  top: 0;
}

.brTablet#nav {
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 20px;
}

#nav-logo {
  height: var(--size-textfield);
  width: auto;
}

.brTablet #nav-logo {
  height: 35px;
}

#nav-options {
  align-items: center;
  display: flex;
  gap: 30px;
}

.brTablet #nav-options {
  justify-content: space-around;
  width: 100%;
  gap: 20px;
}

#nav-options > div {
  position: relative;
}

.nav-route {
  transition: var(--animation);
  text-decoration: none;
  color: var(--primary);
  white-space: nowrap;
}

.nav-route:hover {
  color: var(--stroke);
}

.nav-active {
  color: var(--accent);
  font-weight: 600;
}

.nav-indicator {
  transform: translateX(-50%);
  background: var(--accent);
  border-radius: 10px;
  position: absolute;
  bottom: -7px;
  height: 4px;
  width: 20px;
  left: 50%;
}

.nav:hover > .nav-indicator {
  background: var(--stroke);
}
</style>
