<template>
  <section
    id="homepartners"
    class="section reveal reveal-fade"
    aria-labelledby="aria-homepartners"
    :class="mq"
  >
    <div id="homepartners-text" class="section-top">
      <h1 id="aria-homepartners">Our Trusted Partners</h1>
      <div style="flex-direction: column; gap: 5px" class="true-center">
        <p>
          All our suppliers and partners are well recognised and trusted within
          the dental industry.
        </p>
        <p style="font-size: var(--font-small); font-style: italic">
          (Scroll to view)
        </p>
      </div>
    </div>
    <div id="homepartners-content" class="section-indent">
      <Brand :i="0" class="click-scale" />
      <Brand :i="5" class="click-scale" />
      <Brand :i="3" class="click-scale" />
      <Brand :i="4" class="click-scale" />
      <Brand :i="1" class="click-scale" />
      <Brand :i="2" class="click-scale" />
    </div>
  </section>
</template>

<script>
import mixins from "@/javascript/mixins.js";
import Brand from "@/assets/Brands.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { Brand },
};
</script>

<style scoped>
#homepartners {
  flex-direction: column;
  background: var(--bg);
  padding-bottom: 40px;
  align-items: center;
  padding-top: 40px;
  display: flex;
  width: 100%;
  gap: 10px;
}

#homepartners-content {
  align-items: center;
  overflow-x: scroll;
  padding-top: 40px;
  flex-wrap: nowrap;
  display: flex;
  height: 100%;
  width: 100%;
  gap: 80px;
}

#homepartners-text {
  margin: 0 40px 0 40px;
  flex: 0 0 auto;
}

.brMobile #homepartners-text > h1 {
  font-size: 32px;
}

#homepartners-content > svg {
  filter: grayscale(1) opacity(0.4);
  transition: var(--animation-long);
  flex: 0 0 auto;
  height: auto;
  width: 200px;
}

#homepartners-content > svg:hover {
  filter: unset;
}

#homepartners-content > svg:active {
  transition: var(--animation);
}
</style>
