<template>
  <div class="size true-center">{{ mq.current }}</div>
</template>

<script>
export default {
  inject: ["mq"],
};
</script>

<style scoped>
.size {
  border-radius: var(--border-radius);
  height: var(--size-textfield);
  font-size: var(--font-small);
  padding: 0 20px 0 20px;
  background: var(--bg);
  font-style: italic;
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: 10px;
}
</style>
