<template>
  <section id="homelanding" :class="mq" aria-labelledby="homelanding-title">
    <div id="homelanding-scroll">
      <p>Scroll to continue</p>
      <ChevronDownIcon />
    </div>
    <div class="section img-overlay true-center">
      <h1 class="title" id="homelanding-title">
        Say everything with your smile.
      </h1>
      <Button value="Request Appointment" @click="navContact" />
    </div>
    <img
      :src="image"
      alt="Tooth brush leaning with tooth brushes in a glass in the background."
    />
  </section>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/outline";
import Button from "@/components/global/Button.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { ChevronDownIcon, Button },
  methods: {
    navContact() {
      this.$router.push("/about?appointments");
    },
  },
  computed: {
    image() {
      let type = "tooth-brushes.webp";

      if (this.isMobile) type = "tooth-brushes-mob.webp";

      return require("@/assets/images/" + type);
    },
  },
};
</script>

<style scoped>
#homelanding {
  position: relative;
  overflow: hidden;
  color: white;
  height: 700px;
  width: 100%;
}

#homelanding > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#homelanding > .true-center {
  flex-direction: column;
  gap: 60px;
}

#homelanding-title {
  transition: var(--animation);
  font-size: var(--font-title);
  font-weight: normal;
  text-align: center;
  width: 600px;
}

.brMobile #homelanding-title {
  font-size: var(--font-big);
  width: unset;
}

#homelanding-scroll {
  transform: translateX(-50%);
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  display: flex;
  bottom: 10px;
  z-index: 15;
  left: 50%;
  gap: 5px;
}

#homelanding-scroll > p {
  font-size: var(--font-small);
}

#homelanding-scroll > svg {
  height: var(--font-small);
  width: var(--font-small);
}
</style>
