<template>
  <footer class="footer" :class="mq">
    <div class="footer-container section">
      <div class="footer-column">
        <p class="footer-title">Your Feedback</p>
        <p class="footer-content">
          We greatly appreciate your feedback and look forward to your next
          visit to our practice. Independent reviews are what allow our business
          to become recognised within the community and become the foundation
          for the quality of service we provide to every patient we treat.
        </p>
      </div>
      <div class="footer-column">
        <p class="footer-title">Quick Links</p>
        <div class="footer-column-inner">
          <router-link
            class="footer-content reverse click"
            v-for="r in Routes"
            :key="r.name"
            :to="r.path"
            >{{ r.name }}</router-link
          >
        </div>
      </div>
      <div id="footer-contact" class="">
        <div class="footer-column">
          <p class="footer-title">Contact Us</p>
          <div class="footer-content">
            <p><b>Happy Dental</b></p>
            <p>123 Random Street</p>
            <p>Romsey SO51 ZZ00</p>
          </div>
          <div class="footer-content">
            <p style="padding: 6px 0 6px 0">
              Call:
              <b><a href="TEL:02382444386">02382444386</a></b>
            </p>
            <p style="padding: 6px 0 6px 0">
              Email:
              <b>
                <a href="MAILTO:hello@viscreo.co.uk"> hello@viscreo.co.uk </a>
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- Sub Footer -->
    <!--  -->
    <div class="footer-legal true-center section">
      <div>
        <router-link to="/privacy">Privacy Policy</router-link>
        <div v-if="!isMobile" style="width: 140px" />
      </div>
      <router-link to="/">© 2022 VISCREO. All Rights Reserved. </router-link>
      <a href="http://viscreo.co.uk" target="_blank">
        Designed &amp; developed by VISCREO
      </a>
    </div>
  </footer>
</template>

<script>
import mixins from "@/javascript/mixins.js";
import routes from "@/router/routes.json";

export default {
  inject: ["mq"],
  mixins: [mixins],
  data() {
    return {
      Routes: routes,
    };
  },
};
</script>

<style scoped>
.footer {
  flex-direction: column;
  align-items: stretch;
  position: relative;
  display: flex;
  width: 100%;
}

.footer-container {
  grid-template-columns: 2fr 1fr 1fr;
  justify-content: space-between;
  background: var(--bg);
  padding-bottom: 30px;
  padding-top: 30px;
  min-height: 200px;
  display: grid;
  width: 100%;
  gap: 40px;
}

.brTablet .footer-container {
  grid-template-columns: 1fr 1fr 1fr;
}

.brMobile .footer-container {
  grid-template-columns: 1fr;
  padding-bottom: 60px;
  text-align: center;
  padding-top: 40px;
  gap: 60px;
}

.footer-column,
.footer-column-inner {
  flex-direction: column;
  display: flex;
  gap: 20px;
}

.footer-column * {
  text-decoration: none;
}

.footer-column-inner {
  gap: 10px;
}

.footer-title {
  font-weight: 500;
}

.footer-content,
.footer-content * {
  font-size: var(--font-small);
}

.footer-container b {
  text-decoration: underline;
  padding: 6px 0 6px 0;
  font-weight: 500;
}

.footer-container a {
  color: var(--primary);
  padding: 6px 0 6px 0;
}

.footer-container a:hover {
  text-decoration: underline;
  color: var(--accent);
}

.footer-legal {
  justify-content: space-between;
  font-size: var(--font-small);
  background: #006bff;
  align-items: center;
  text-align: center;
  display: flex;
  height: 40px;
}

.footer-legal > div {
  display: flex;
  gap: 20px;
}

.footer-legal a {
  color: white;
}

.brMobile .footer-legal {
  padding: 20px 0 20px 0;
  flex-direction: column;
  height: min-content;
  align-items: center;
  gap: 20px;
}
</style>
