<template>
  <div class="section seperator" :class="mq"><div /></div>
</template>

<script>
export default {
  inject: ["mq"],
};
</script>

<style scoped>
.seperator {
  transition: var(--animation);
  width: 100%;
  height: 3px;
}

.seperator > div {
  background: var(--stroke);
  border-radius: 10px;
  height: 100%;
  width: 100%;
}
</style>
