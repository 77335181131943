<template>
  <div :id="id" class="page" :class="mq">
    <Anchor :value="id" :enabled="showAnchor" />
    <slot />
    <Size v-if="false" />
    <Disclaimer v-if="true" />
    <Footer />
  </div>
</template>

<script>
import Disclaimer from "@/components/Disclaimer.vue";
import Anchor from "@/components/core/Anchor.vue";
import Footer from "@/components/core/Footer.vue";
import Size from "@/components/core/Size.vue";

export default {
  inject: ["mq"],
  props: { id: String },
  components: {
    Disclaimer,
    Anchor,
    Footer,
    Size,
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return { showAnchor: false };
  },
  methods: {
    onScroll() {
      /* Anchor Function */
      var nav = document.getElementById(this.id);
      var offset = nav.offsetTop;

      if (window.pageYOffset > offset) {
        this.showAnchor = true;
      } else {
        this.showAnchor = false;
      }

      /* Reveal Class */
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 200;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("reveal-active");
        } else {
          reveals[i].classList.remove("reveal-active");
        }
      }
    },
  },
};
</script>

<style scoped>
.page {
  padding-top: calc(var(--size-nav) + 20px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  gap: 160px;
}

.brTablet.page {
  padding-top: var(--size-nav);
}

.brMobile.page {
  padding-top: var(--size-nav-mob);
}

.page#home.brMobile {
  padding: unset;
  height: unset;
  margin: unset;
}
</style>
