<template>
  <div class="treatment true-center click" @click="nav">
    <Icon :value="icon" class="treatment-icon" />
    <div class="true-center">
      <h3>{{ name }}</h3>
      <p>{{ value }}</p>
    </div>
  </div>
</template>

<script>
import Icon from "@/assets/Icons.vue";

export default {
  props: {
    value: String,
    icon: String,
    name: String,
  },
  components: { Icon },
  methods: {
    nav() {
      this.$router.push("about?" + this.name);
    },
  },
};
</script>

<style scoped>
.treatment {
  border-radius: var(--border-radius);
  border: 3px solid transparent;
  flex-direction: column;
  text-align: center;
  padding: 20px;
  gap: 30px;
}

.treatment:hover {
  border: 3px solid var(--accent);
}

.treatment-icon {
  height: 60px;
  width: 60px;
}

.treatment > div {
  flex-direction: column;
  gap: 10px;
}

.treatment h2 {
  font-weight: 500;
}
</style>
