<template>
  <transition name="fade">
    <div v-if="enabled" class="anchor click true-center" @click="scrollTop">
      <ChevronUpIcon />
      <p class="anchor-txt">Scroll To Top</p>
    </div>
  </transition>
</template>

<script>
import { ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon },
  props: {
    enabled: Boolean,
    value: String,
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        behavior: "smooth",
        top,
      });
    },
  },
};
</script>

<style scoped>
.anchor {
  border-radius: var(--border-radius);
  height: var(--size-textfield);
  width: var(--size-textfield);
  background: var(--bg);
  color: var(--primary);
  position: fixed;
  z-index: 95;
  bottom: 10px;
  right: 10px;
}

.anchor:hover {
  background: var(--primary);
  padding: 0 15px 0 15px;
  width: min-content;
  color: white;
  gap: 10px;
}

.anchor:active {
  background: var(--accent);
  color: white;
}

.anchor > svg {
  height: var(--size-icon);
  width: var(--size-icon);
}

.anchor-txt {
  font-size: var(--font-small);
  white-space: nowrap;
  visibility: hidden;
  margin-top: 4px;
  max-width: 0;
  opacity: 0;
}

.anchor:hover .anchor-txt {
  visibility: visible;
  max-width: unset;
  opacity: 1;
}
</style>
