<template>
  <section
    id="hometreatments"
    class="section-indent reveal reveal-fade"
    aria-labelledby="aria-hometreatments"
    :class="mq"
  >
    <div class="section-top">
      <h1 id="aria-hometreatments">Our Treatments</h1>
      <p style="font-size: var(--font-small); color: var(--grey)">
        Click a service below to learn more.
      </p>
    </div>

    <div id="hometreatments-content" class="reveal">
      <Treatment
        :class="'delay' + index"
        v-for="(t, index) in treatments"
        :value="t.value"
        :icon="t.icon"
        :name="t.name"
        :key="t.name"
      />
    </div>
  </section>
</template>

<script>
import Treatment from "@/components/Treatment.vue";

export default {
  inject: ["mq"],
  components: {
    Treatment,
  },
  data() {
    return {
      treatments: [
        {
          icon: "care",
          name: "Regular Checkups",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        },
        {
          icon: "brushing",
          name: "Dental Hygiene",
          value: "Et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
          icon: "braces",
          name: "Braces & Alignments",
          value:
            "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          icon: "tooth",
          name: "Teeth Whitening",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing eli tempor incididunt.",
        },
        {
          icon: "cavity",
          name: "Crowns",
          value:
            "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          icon: "chip",
          name: "Tooth Repairs",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing eli tempor incididunt.",
        },
        {
          icon: "baby",
          name: "Children",
          value: "Et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
          icon: "implant",
          name: "Dental Implants",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
        },
      ],
    };
  },
};
</script>

<style scoped>
#hometreatments {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  gap: 60px;
}

#hometreatments-content {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  display: grid;
  row-gap: 20px;
}

.brLaptop #hometreatments-content {
  grid-template-columns: 1fr 1fr 1fr;
}

.brTablet #hometreatments-content {
  grid-template-columns: 1fr 1fr;
}

.brMobile #hometreatments-content {
  grid-template-columns: 1fr;
}

#hometreatments-content > .treatment {
  opacity: 0;
}

#hometreatments-content.reveal-active > .treatment {
  opacity: 1;
}
</style>
