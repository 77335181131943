<template>
  <section class="report section-indent true-center">
    <div class="true-center">
      <p style="color: var(--accent)">
        Want to find out more about how performant and efficient we make our
        websites?
      </p>
      <h1>Why not read Google&#39;s report on how Happy Dental performs!</h1>
    </div>
    <Button
      :value="hover ? 'Run on 26-July-2022' : 'View Report'"
      :accent="true"
      @click="navReport"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    />
  </section>
</template>

<script>
import Button from "@/components/global/Button.vue";

export default {
  components: { Button },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    navReport() {
      window.open("https://showcase-dental.viscreo.co.uk/metrics.html");
    },
  },
};
</script>

<style scoped>
.report {
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.report > div {
  flex-direction: column;
  gap: 5px;
}
</style>